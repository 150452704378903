/**
 * TODO: Explain this
 */
import React from 'react';
import { AccessibleOnClickPropsHandler } from './accessible-on-click-props-types';

export function makeAccessibleOnClickProps<
  Element extends HTMLElement = HTMLElement
>(handler: AccessibleOnClickPropsHandler<Element>) {
  return {
    onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
      return handler(e);
    },
    onKeyPress: (e: React.KeyboardEvent<Element>) => {
      /*
        TIP:
        you can use the HTML element type from the event
        to change the accessibility behaviour.
      */
      // if(e.target instanceof HTMLDivElement){
      //   // Do something
      // }

      if (e.keyCode === 13 || e.keyCode === 32) {
        return handler(e);
      }
    },
    role: 'button',
  };
}
