/**
 * React custom hook to generate unique id
 */
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

/**
 * Currently returns undefined on the server side,
 * since useState does keep the value between server rendering and client side hydration
 */
export function useUniqueHTMLElementId(
  reuseHTMLElementId?: string,
): string | undefined {
  const [htmlElementId, replaceHtmlElementId] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    replaceHtmlElementId(reuseHTMLElementId || `dom-id-${v4()}`);
  }, []);

  return htmlElementId;
}
