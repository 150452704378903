/**
 * 404 Not Found Template - When user hits or redirect to any invalid route, this 404Page Template is rendered, which calls another NotFoundTemplate component
 */
import { NotFoundTemplate } from 'src/components/templates/not-found/not-found-template'

/**
 * @function 404 Page
 * @category Pages
 * @description When user hits or redirect to any invalid route, this 404Page Template is rendered, which calls another NotFoundTemplate component
 * @returns JSX of 404PageNotFound
 */

export default NotFoundTemplate
