/* 
  Header compoenet which renders expanded/collapsed Navbar on windows resize 
*/
import React, { useEffect, useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { makeAccessibleOnClickProps } from 'src/components/ui-kit/core/accessibility/make-accessible-on-click-props'
import * as styles from './header.module.scss'
import { HEADER_CSS_CLASS } from './header-constants'
import { LinkAnchor } from 'src/components/ui-kit/protons/link-anchor/link-anchor'
import { INDEX_ROUTE } from 'src/components/templates/index/index-routes'
import { Nav } from 'react-bootstrap'
import { PROJECT_NAME } from 'src/project-details'
import { useUniqueHTMLElementId } from 'src/components/ui-kit/core/utils/use-unique-html-element-id'
import { missingCssClass } from 'src/components/ui-kit/core/utils/missing-css-class'

export function Header() {
  /**
   * useUniqueHTMLElementId hook returns a unique id which can be used to identifies 
   * the element(s) that are controlled by the current element, 
   * when that relationship isn’t represented in the DOM.
   * refer https://tink.uk/using-the-aria-controls-attribute/ for more information
   */
  const menuDomId = useUniqueHTMLElementId()
  const [expanded, replaceExpanded] = useState<boolean>(false)

  // Fn to open Navbar menu
  const expandMenu = () => {
    replaceExpanded(true)
  }
  // Fn to close Navbar menu
  const collapseMenu = () => {
    replaceExpanded(false)
  }

  useEffect(() => {
    /*
    function to detect window resize
    for reference check https://css-tricks.com/snippets/jquery/done-resizing-event/
  */
    const resizeHandler = () => {
      if (expanded) {
        collapseMenu()
      }
    }

    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <>
      <header className={`sticky-top border-bottom ${HEADER_CSS_CLASS}`}>
        <Navbar collapseOnSelect expand="lg" expanded={expanded} variant="light" className="bg-light">
          <Navbar.Toggle
            onClick={() => {
              if (expanded) {
                collapseMenu()
              } else {
                expandMenu()
              }
            }}
            aria-controls={menuDomId}
          />
          <LinkAnchor className="navbar-brand" href={INDEX_ROUTE.getHref()}>
            <span className="badge badge-primary">
              <span className="h5">{PROJECT_NAME}</span>
            </span>
          </LinkAnchor>
          <Navbar.Collapse id={menuDomId}>
            <div className="container">
              <Nav>
                <li className="nav-item"></li>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </header>
      {expanded ? (
        <div
          {...makeAccessibleOnClickProps(() => collapseMenu())}
          className={styles['header__backdrop'] || missingCssClass()}
        />
      ) : null}
    </>
  )
}
