/*
  Authentication route access constants used to check if route should be ALlowed or Blocked
*/
export enum AuthenticatedRouteAccess {
  Allow = 'a',
  Block = 'b',
}
/*
  Authenticated route rule type
*/
type AuthenticatedRouteRule = {
  access: AuthenticatedRouteAccess
  hrefToRedirectTo?: string
}
/*
  Authenticated routes rule type
*/
export type AuthenticatedRouteRules = {
  mainApiSession: AuthenticatedRouteRule
}
